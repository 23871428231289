
import SupportMessage from "@/components/SupportMessage"
import { useNuxtApp } from "#app"
import Vue from "vue"
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"
import { useCameraStore } from "@/stores/camera"
import { Route } from "vue-router"
import { useProjectStore } from "@/stores/project"

export default Vue.extend({
  components: {
    SupportMessage,
  },
  layout: "empty",
  props: {
    error: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      internetStatus: false,
      noRedirect: true,
      coundown: 5,
      countdownInterval: null,
    }
  },
  head() {
    let title: string
    if (this.noRedirect) {
      title = this.error.message
    } else {
      title = "loading..."
    }

    return {
      title,
      meta: [
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1, maximum-scale=1",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useAccountStore, useCameraStore, useProjectStore),
  },
  beforeMount() {
    this.noRedirect = true
    if (this.error && this.error.statusCode !== 404) {
      this.$errorTracker.save(this.error, "generic")
    }
    if (!this.cameraStore.cameras) {
      this.handleErrorCode()

      return
    }

    if (this.$route.params.mediaExid && this.error.statusCode == 404) {
      this.countdownFunc()
    }

    if (!(this.error.statusCode === 404)) {
      this.handleErrorCode()
    }
  },
  mounted() {
    this.checkInternetConnectivity()
  },
  beforeDestroy() {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval)
    }
  },
  methods: {
    countdownFunc() {
      this.countdownInterval = setInterval(() => {
        this.coundown--
        if (this.coundown == 0) {
          clearInterval(this.countdownInterval)
          this.$router.push(`${this.projectStore.projectRoute}/media-hub`)
        }
      }, 1000)
    },
    handleErrorCode() {
      this.noRedirect = false
      if (
        this.error.statusCode === 500 &&
        !useNuxtApp().vue2App.context.isDev
      ) {
        this.$router.push("/server-down")
      } else if (this.error.statusCode === 401) {
        if (this.$route) {
          this.accountStore.redirectUrl = this.$route.fullPath
        }

        this.$router.push({
          path: "/v2/users/signin",
          query: { force: true },
        } as unknown as Route)
      }
    },
    checkInternetConnectivity() {
      this.internetStatus = !navigator.onLine
      this.$setTimeout(() => this.checkInternetConnectivity(), 1000)
    },
  },
})
